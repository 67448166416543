import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PortableText from 'react-portable-text';

import SEO from '../components/layout/SEO';
import Breadcrumb from '../components/layout/Breadcrumb';
import SidebarLinks from '../components/widgets/SidebarLinks';
import SidebarContact from '../components/widgets/sidebarContact';
import ServiceHeader from '../components/service/ServiceHeader';

const AboutStyles = styled.div`
  .content {
    padding: 60px 0;
    display: grid;
    grid-template-columns: 1fr 3.5fr;
    grid-gap: 50px;
  }
  @media only screen and (max-width: 780px) {
    .content {
      grid-template-columns: 1fr;
    }
  }

  .hr {
    max-width: 10%;
    background-color: var(--accent);
    height: 2px;
    margin-bottom: 30px;
  }

  .main-content {
    h2 {
      margin-top: 0;
    }
    img {
      max-width: 100%;
    }
  }

  .sidebar {
    .links {
      background: #414141;
      padding: 20px;
    }
    p {
      margin: 0;
    }
  }
`;

export default function SingleServicePage({ data, location }) {
  const { service, services } = data;

  return (
    <AboutStyles>
      <SEO
        title={service.name}
        location={location.href}
        description={service.blurb.en}
      />
      <Breadcrumb title={service.name}>
        <li>
          <Link to="/">Home</Link>
        </li>

        <li>
          <Link to="/services">Services</Link>
        </li>

        <li>
          <Link to={`/${service.slug.current}`}>{service.name}</Link>
        </li>
      </Breadcrumb>

      <div className="container content flex">
        <div className="sidebar">
          <SidebarLinks>
            {services.nodes.map((serv) => (
              <li key={serv.id}>
                <Link to={`/${serv.slug.current}`}>{serv.name}</Link>
              </li>
            ))}
          </SidebarLinks>

          <SidebarContact />
        </div>

        <div className="main-content">
          <ServiceHeader
            title={service.name}
            subheading={service.blurb.en}
            image={service.image.asset.url}
          />

          <div className="main-content">
            {service._rawBody ? (
              <PortableText content={service._rawBody} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </AboutStyles>
  );
}

export const query = graphql`
  query ($id: String!) {
    service: sanityServices(id: { eq: $id }) {
      name
      _rawBody
      blurb {
        en
      }
      slug {
        current
      }
      image {
        asset {
          url
        }
      }
    }

    services: allSanityServices(sort: { fields: name, order: ASC }) {
      nodes {
        id
        name
        slug {
          current
        }
      }
    }
  }
`;

SingleServicePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};
