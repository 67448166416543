import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ServiceHeaderStyles = styled.header`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 30px;
  .text {
    padding: 30px;
    background: #f7f7f7;
    p {
      color: #666;
    }
    h2 {
      font-size: 28px;
      line-height: 39px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--heading);
    }
  }
  .image {
    background-position: center;
    background-size: cover;
  }
  @media only screen and (max-width: 810px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    .image {
      height: 350px;
    }
  }
`;

export default function ServiceHeader({ title, subheading, image }) {
  return (
    <ServiceHeaderStyles>
      <div className="text">
        <h2>{title}</h2>
        <div className="hr" />
        <p>{subheading}</p>
      </div>

      <div className="image" style={{ backgroundImage: `url('${image}')` }} />
    </ServiceHeaderStyles>
  );
}

ServiceHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
